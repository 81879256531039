import React from 'react';

const ItemDetails = ({ item, index }) => {
  if (!item) {
    return <div>jhhhhhh...</div>;
  }

  return (
    <div className="item-details mt-5 pt-3">
      <h1>{item.item_name}</h1>
      <img src={item.image} alt={item.item_name} />
      <div style={{ marginTop: '20px' }}>
        <p>Price: ₦{item.unit_price}</p>
        <p>{item.body}</p>
       <h2>Aisha</h2>
      </div>
    </div>
  );
};

export default ItemDetails;
