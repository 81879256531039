import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addCart, getStockList, updateCart } from "../redux/action/shop";
import { _get, globalColor, separator, useWindowWidth } from "../utils/Helper";
import { FaArrowLeft, FaStar } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import defaultImg from "../Images/No-Image-Placeholder.jpg";
import "./carttable.css";

export default function Category() {
  const navigate = useNavigate();
  const { carts, stocks } = useSelector((s) => s.shop);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { category } = useParams();
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const skeleton = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1];

  useEffect(() => {
    setLoading(true);
    _get(
      `api/get-category?category=${category}`,
      (res) => {
        setData(res.result);
        setLoading(false);
        setMessage(res.message);
      },
      (err) => {
        setError(err);
      }
    );
  }, [category]);

  useEffect(() => {
    setSortedData(
      data?.filter(
        (product) =>
          product.product_status === "available" || product.product_quantity
      )
    );
  }, [data]);

  const handleItemClick = (item) => {
    navigate(`/category/${category}/${item}`);
  };

  const width = useWindowWidth();
  const userDetails = useSelector((userDetails) => userDetails);
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className="container-mod"
        style={{ marginTop: width < 768 ? "8rem" : "9rem" }}
      >
        {/* <Button
          onClick={goBack}
          className="cont-bnt mb-3 d-flex align-items-center"
        >
          <FaArrowLeft className="me-2" />
          Back
        </Button> */}
        <div className="mt-3 shop-main-card">
          <h2 className="text-start mb-4 mt-0">
            {/* {JSON.stringify(userDetails)}jjjhhjhj */}
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </h2>
          {loading ? (
            <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-5 row-cols-xl-6 g-3">
              {skeleton.map((holder, index) => (
                <div className="px-2" key={index}>
                  <div className="shadow rounded p-0">
                    <div className="ratio ratio-1x1 bg-light overflow-hidden rounded-top">
                      <Skeleton className="h-100 w-100" />
                    </div>
                    <div className="px-2">
                      <Skeleton className="my-3 w-75" />
                      <Skeleton className="ps-2 w-50" />
                      <Skeleton className="mt-1 w-50" height={10} />
                      <Skeleton className="my-2 w-25" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : sortedData?.length > 0 ? (
            <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-5 row-cols-xl-6 g-3">
              {sortedData?.map((item, idx) => (
                <div
                  key={idx}
                  className="text-decoration-none"
                  onClick={() => handleItemClick(item.id)}
                >
                  <div className="shadow rounded category-img-div">
                    <div className="ratio ratio-1x1 bg-light overflow-hidden rounded-top">
                      <img
                        alt={item.product_name}
                        src={
                          item.image_urls
                            ? item.image_urls.split(",")[0]
                            : defaultImg
                        }
                        className="w-100 h-100 object-fit-cover category-img"
                      />
                    </div>
                    <h5 className="mt-3 mb-3 ps-2 fw-large text-dark category-title">
                      {item.product_name.charAt(0).toUpperCase() +
                        item.product_name.slice(1)}
                    </h5>
                    <div className="mt-2 ps-2 d-flex flex-column">
                      <div className="d-flex rating">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <FaStar
                            key={rating}
                            className={`text-warning`}
                            style={{
                              height: "20px",
                              width: "20px",
                              flexShrink: 0,
                            }}
                          />
                        ))}
                      </div>
                      <p className="mt-1 mb-0 small text-muted">10 reviews</p>
                    </div>
                    <p className="mt-2 pb-3 ps-2 fw-medium text-muted small">
                      ₦{separator(item.product_price)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Alert
              style={{
                backgroundColor: globalColor.color3,
                borderColor: globalColor.color3,
                color: globalColor.color2,
              }}
            >
              {message}
            </Alert>
          )}

          {/* {sortedData?"there something":<Alert color="danger">There No Product In This Category</Alert>} */}
        </div>
      </div>
    </>
  );
}
