import { TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import {
  FaRegHeart,
  FaStar,
  FaRecycle,
  FaTruck,
  FaArrowLeft,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action/shop";
import { _get, separator } from "../utils/Helper";
import "./carttable.css";

const product = {
  name: "Zip Tote Basket",
  price: "₦140,000",
  rating: 4,
  images: [
    {
      id: 1,
      name: "Angled view",
      src: "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg",
      alt: "Angled front view with bag zipped and handles upright.",
    },
    {
      id: 2,
      name: "Angled view",
      src: "https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg",
      alt: "Angled front view with bag zipped and handles upright.",
    },
    // More images...
  ],
  description: `The Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.`,
};


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDet() {
  const [key, setKey] = useState("Reviews");
  const [favorite, setFavorite] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");

  const handleAddToCart = () => {
    dispatch(addCart({ ...data, qty: quantity, selectedImage }));
    navigate("/cart");
    // console.log(selectedImage)
  };

  const handleFavorite = () => {
    setFavorite(!favorite);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  useEffect(() => {
    setLoading(true);
    setError(null);

    _get(
      `api/products/${id}`,
      (res) => {
        setData(res.result);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  }, [id]);

  useEffect(() => {
    if (data.image_urls) {
      const imageUrls = data.image_urls.split(",");
      setSelectedImage(imageUrls[0]);
    }
  }, [data]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const imageUrls = data.image_urls ? data.image_urls.split(",") : [];

  return (
    <div className="bg-white">
      <div className="container py-4 pb-5" style={{ marginTop: "8rem" }}>
        <Button
          onClick={goBack}
          className="cont-bnt mb-3 d-flex align-items-center"
        >
          <FaArrowLeft className="me-2" />
          Back
        </Button>
                  {/* {JSON.stringify(data)} */}

        <Row>
          <Col md={8}>
            <div className="row shadow py-4 " style={{ boxShadow: "" }}>
              <div className="sm-scrn-selectedimg">
                <Row className="sm-scrn-selectedimg-row">
                  <Col md={9}>
                    <TabGroup className="">
                      <TabPanels className="w-100">
                        {/* {JSON.stringify(imageUrls)} */}
                        <TabPanel>
                          <div style={{ height: "45vh" }}>
                            <img
                              alt={data.product_name}
                              src={selectedImage}
                              className="h-100 object-fit-cover rounded-sm"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </Col>
                  <Col md={3} style={{ display: "" }}>
                    <TabGroup>
                      <TabPanels className="mt-4">
                        <div
                          className="d-grid sm-scrn-tab"
                          // style={{
                          //   gridTemplateColumns: "repeat(10, 1fr)",
                          //   gap: "10px",
                          // }}
                        >
                          {imageUrls?.map((item, index) => (
                            <img
                              key={index}
                              alt={data.product_name}
                              src={item}
                              className="object-fit-cover rounded sm-scrn-img"
                              style={{
                                width: "100%",
                                height: "10vh",
                                marginRight: "10px",
                                marginBottom: "10px",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                              onClick={() => handleImageClick(item)}
                            />
                          ))}
                        </div>
                      </TabPanels>
                    </TabGroup>
                  </Col>
                </Row>
              </div>
              <Col md={6} className="lg-scrn-selectedimg">
                <TabGroup className="d-flex flex-column align-items-start">
                  <TabPanels className="w-100">
                    {/* {JSON.stringify(imageUrls)} */}
                    <TabPanel>
                      <div style={{ height: "45vh" }}>
                        <img
                          alt={data.product_name}
                          src={selectedImage}
                          className="h-100 object-fit-cover rounded"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </Col>
              <Col md={6}>
                {/* Product details done by mide */}
                <div className=" mt-4 mt-lg-0">
                  <h1
                    className="display-4 text-dark product-name"
                    style={{ fontWeight: "500" }}
                  >
                    {data.product_name}
                  </h1>

                  <div className="mt-0">
                    <p className="h3 text-dark product-price">
                      ₦{separator(data.product_price)}
                    </p>
                  </div>

                  {data.product_size ? (
                    <div className="mt-2">
                      <div className="text-light pb-2">
                        <h4
                          className="fw-bold d-inline p-1 product_size"
                          style={{ background: "#542b2b", fontSize: "15px" }}
                        >
                          {data.product_size}
                        </h4>
                      </div>
                    </div>
                  ) : null}

                  <div className="mt-2">
                    <div className="text-dark">
                      <h5 className="fw-bold d-inline product_qty_lbl">Available : </h5>
                      <h4 className="fw-bold d-inline product_qty">
                        {data.product_quantity}
                      </h4>
                    </div>
                  </div>

                  {/* Total Reviews done by mide*/}
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <FaStar
                            key={rating}
                            aria-hidden="true"
                            className={classNames(
                              product.rating > rating
                                ? "text-primary"
                                : "text-muted",
                              "h-5 w-5"
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="text-dark">
                      <h5 className="fw-bold d-inline product_description">Description: </h5>
                      {data.product_description}
                    </div>
                  </div>

                  <div className="mt-4 mb-3 d-flex align-items-center ">
                    <span className="fw-bold">Quantity: </span>
                    <div className="quantity-selector mt-3 d-inline d-flex align-items-center mx-4">
                      <Button
                        className="quantity-btn"
                        style={{backgroundColor:"#542b2b",color:'white', fontSize:"13px"}}
                        onClick={() => handleQuantityChange(-1)}
                      >
                        -
                      </Button>
                      <span className="quantity-display mx-1 p-2 rounded" style={{background:"#a52a2a", color:'#f2eadb',fontSize:'15px'}}>{quantity}</span>
                      <Button
                        className="quantity-btn"
                        style={{backgroundColor:"#542b2b",color:'white', fontSize:"15px"}}
                        onClick={() => handleQuantityChange(1)}
                      >
                        +
                      </Button>
                    </div>
                  </div>

                  <form className="mt-0">
                    <div className="mt-2 d-flex add-to-cart">
                      <button
                        type="submit"
                        className="add flex-grow-1"
                        onClick={handleAddToCart}
                      >
                        Add to cart
                      </button>

                      <button
                        type="button"
                        className="btn btn-danger ml-3"
                        onClick={handleFavorite}
                      >
                        <FaRegHeart className="h-6 w-6" />
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
              <Col md={12}>
                <TabGroup className="lg-scrn-tab">
                  <TabPanels className="mt-4">
                    <div
                      className="d-grid"
                      style={{
                        gridTemplateColumns: "repeat(10, 1fr)",
                        gap: "10px",
                      }}
                    >
                      {imageUrls?.map((item, index) => (
                        <img
                          key={index}
                          alt={data.product_name}
                          src={item}
                          className="object-fit-cover rounded-sm"
                          style={{
                            width: "100%",
                            height: "10vh",
                            marginRight: "10px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            borderRadius: "10px",
                          }}
                          onClick={() => handleImageClick(item)}
                        />
                      ))}
                    </div>
                  </TabPanels>
                </TabGroup>
              </Col>
            </div>
          </Col>
          <Col md={4} className="other-details">
            <Card className="mb-4">
              <CardHeader tag="h5" className="bg-white">
                {" "}
                Delivery and Returns:
              </CardHeader>
              <CardBody>
                <FaTruck className="truck" style={{ fontSize: "30px" }} />{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontStyle: "bold",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Delivery{" "}
                </span>
                <CardText>Estimated delivery time 1-9 business days</CardText>
                <CardText>Express Delivery Available</CardText>
                <CardText>
                  For Same-Day-Delivery: Please place your order before 11AM
                </CardText>
                <hr style={{ opacity: ".1" }}></hr>
                <FaRecycle
                  className="truck"
                  style={{ fontSize: "30px" }}
                />{" "}
                <span
                  style={{
                    fontSize: "20px",
                    fontStyle: "bold",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Return Policy{" "}
                </span>
                <CardText>
                  Next-Day-Delivery: Orders placed after 11AM will be delivered
                  the next day
                </CardText>
                <CardText>Note: Availability may vary by location</CardText>
                <a href="#terms" className="card-link">
                  Terms and conditions apply
                </a>
              </CardBody>
            </Card>
            <Card className="d-none">
              <CardBody>
                <CardTitle tag="h3"> Sellers Informationns </CardTitle>
                <CardText>Estimated delivery time 1-9 business days</CardText>
                <CardText>Express Delivery Available</CardText>
                <CardText>
                  For Same-Day-Delivery: Please place your order before 11AM
                </CardText>
                <CardText>
                  Next-Day-Delivery: Orders placed after 11AM will be delivered
                  the next day
                </CardText>
                <CardText>Note: Availability may vary by location</CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
