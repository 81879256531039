import React from "react";
import { Outlet } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ShopNavbar from "../Shop/ShopNavbar";
import Footer from  '../Shop/footer/footer'
import ItemDetails from "../Shop/ItemDetails";


 function AppIndex() {
  return (
    <div>
    
      <Row className="m-0 p-0">
      
        <Col md={12} className="m-0 p-0 m">
       
          <ShopNavbar />
         
          {/* <ShopItems /> */}
          {/* <AccountSidebar /> */}
          
        </Col>
      </Row>
      <Row className="m-0 p-0">
        <Col md={12}>
          <Outlet />
        
        </Col>
        
        <Footer/>
      </Row>
    </div>
  );
}
export default AppIndex;
