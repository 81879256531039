import React, { useState } from "react";
import { Edit } from "react-feather";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import profile from "../../Images/ysquareimperial.png";
import { toast } from "react-toastify";
import "./Dashboard.css";
export default function AccountHome() {
  const {
    auth: { user },
  } = useSelector((s) => s);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [bank, setBank] = useState("");
  const [amount, setAmount] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Submitted Successfully");
    // Handle form submission logic here
    console.log({ selectedOption, bank, amount, accountNumber });
  };

  return (
    <div>
      <Card className="sidebar-card px-4 py-4 shadow-sm orders-div">
        <div className="profile">
          <div>
            <h4 className="headings" style={{ margin: 0, padding: 0 }}>
              My Profile Details
            </h4>
          </div>
          <hr />
          <Row>
            <Col md={6}>
              <Card className="shadow-sm profile-card">
                <div className="p-2 d-flex justify-content-between align-items-center">
                  <div>
                    <h6>User Information</h6>
                  </div>
                  <div>
                    <Edit className="profile-icon" size="1.3em" title="edit" />
                  </div>
                </div>
                <hr style={{ margin: 0, padding: 0 }}></hr>
                <div className="p-2">
                  <div className="user-info-div">
                    <h4>Full Name</h4>
                    <p>
                      {user.firstname} {user.lastname}
                    </p>
                  </div>
                  <div className="user-info-div">
                    <h4>Email Address</h4>
                    <p>{user.email}</p>
                  </div>
                  <div className="user-info-div">
                    <h4>Phone Number</h4>
                    <p>{user.phone}</p>
                  </div>
                  <hr style={{ margin: 0, padding: 0 }}></hr>
                  <div className="py-2 px-2">
                    <h6 className="wallet-title">Wallet Summary</h6>
                  </div>
                  <hr style={{ margin: 0, padding: 0 }}></hr>
                  <div className="p-2 d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="d-flex flex-column justify-content-center align-items-center wallet-div">
                        <h6>Wallet Balance</h6>
                        <h4>₦0</h4>
                      </div>
                      {/* <div>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle
                            caret
                            style={{ background: "transparent", width: "100%" }}
                          >
                            Fund My Wallet
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => handleSelect("bank")}>
                              Bank Transfer
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleSelect("mobile")}
                            >
                              Mobile Payment
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>

                        {selectedOption === "bank" && (
                          <Form
                            onSubmit={handleSubmit}
                            style={{ marginTop: "20px" }}
                          >
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="bankSelect">Select Bank</Label>
                                  <Input
                                    type="select"
                                    id="bankSelect"
                                    value={bank}
                                    onChange={(e) => setBank(e.target.value)}
                                  >
                                    <option value="">Choose a bank</option>
                                    <option value="bank1">Bank 1</option>
                                    <option value="bank2">Bank 2</option>
                                    <option value="bank3">Bank 3</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="amount">Amount</Label>
                                  <Input
                                    type="number"
                                    id="amount"
                                    placeholder="Enter amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="accountNumber">
                                    Account Number
                                  </Label>
                                  <Input
                                    type="text"
                                    id="accountNumber"
                                    placeholder="Enter account number"
                                    value={accountNumber}
                                    onChange={(e) =>
                                      setAccountNumber(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <div class="d-flex justify-content-center">
                                <Button
                                  type="submit"
                                  color="primary"
                                  style={{
                                    background: "#542b2b",
                                    color: "white",
                                    width: "auto",
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Row>
                          </Form>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col md={6} className="shippin-col">
              <Card className="profile-card shadow-sm">
                <div className="p-2 d-flex justify-content-between align-items-center">
                  <div>
                    <h6>Shipping Information</h6>
                  </div>
                  <div>
                    <Edit className="profile-icon" size="1.3em" title="edit" />
                  </div>
                </div>
                <hr style={{ margin: 0, padding: 0 }}></hr>
                <div className="p-2">
                  <div className="user-info-div">
                    <h4 style={{ fontWeight: "bold" }}>Username</h4>
                    <p>{user.username}</p>
                  </div>
                  <div className="user-info-div">
                    <h4 style={{ fontWeight: "bold" }}>Home Address</h4>
                    <p>{user.address}</p>
                  </div>
                  <div className="user-info-div">
                    <h4 style={{ fontWeight: "bold" }}>Shipping Address</h4>
                    <p>Alheri Bread</p>
                  </div>
                  <div className="user-info-div">
                    <h4 style={{ fontWeight: "bold" }}>Contact</h4>
                    <p>{user.phone}</p>
                  </div>
                  {/* <p style={{ fontWeight: "bold", margin: 0, padding: 0 }}>
                    {user.username}
                    <span>, {user.phone}</span>
                  </p>
                  <p style={{ margin: 0, padding: 0, fontSize: 13 }}>
                    {user.address}
                  </p>
                  <p style={{ margin: 0, padding: 0, fontSize: 13 }}>
                    {user.state}
                  </p> */}
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}
