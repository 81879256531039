
import React from 'react'
import { Card, CardTitle, Container } from 'reactstrap'
import './footer.css';
import kasuwalogo from '../../Images/KASUWAMALL (1).png'
import { Link } from 'react-router-dom';

const Terms_Cond = () => {
  return (
    <div className='term-div'>
      <Container className='mt-5 g-terms-containe'>
        <h1>General Terms & Condition </h1>
        {/* <Card className='term-card'>
          <div className='term-head'>
            <p>Welcome to Kasuwa Mall. By accessing or using our platform, you agree to comply with the following terms and conditions. Please read them carefully before making any purchases or listing any products.</p>
          </div>
          <div className='logo_cont'></div>
        </Card> */}
        <div className='container p-4'>
          <h3 className='h3-term'>1. Introduction</h3>
          <p className='term-p'>
            Welcome to Kasuwa Mall. By accessing or using our platform, you agree to comply with the following terms and conditions. Please read them carefully before making any purchases or listing any products.
            These Terms and Conditions govern your use of our e-commerce platform, including the display and sale of products by vendors and the logistics services we offer.
          </p>
          <h3 className='h3-term'>2. Eligibility</h3>
          <p className='term-p'>
            By using this website, you confirm that you are at least 18 years of age or have received permission from a parent or legal guardian to enter into transactions on the site.
          </p>
          <h3 className='h3-term'>3. Vendor Responsibilities</h3>
          <p className='term-p'>
            Vendors are responsible for ensuring the accuracy, legality, and quality of the products they list on the platform.
Vendors must provide complete and truthful information about their products, including but not limited to price, description, and stock availability.
Vendors are solely responsible for complying with all applicable laws and regulations regarding the sale of their products.

          </p>
          <h3 className='h3-term'>4. Customer Responsibilities</h3>
          <p className='term-p'>Customers are responsible for ensuring the accuracy of their delivery details when placing an order.
Customers must review all product details carefully before making a purchase, including prices, descriptions, and delivery options.
All purchases are final once confirmed, unless otherwise stated in our Returns and Refunds Policy.
</p>
          <h3 className='h3-term'>5. Purchases and Payment
</h3>
          <p className='term-p'>We offer various payment methods, including online payment options. All prices are listed in Naira and include applicable taxes unless stated otherwise.
Payment must be made in full at the time of purchase. We reserve the right to cancel or refuse any order for any reason.
</p>
          <h3 className='h3-term'>6. Logistics and Delivery</h3>
          <p className='term-p'>Upon a customer's request, we offer logistics services to deliver purchased items. Delivery timelines may vary depending on location and availability.
We strive to ensure timely deliveries, but delays may occur due to factors beyond our control. In such cases, we will notify you promptly.
Shipping fees, if applicable, will be clearly outlined during the checkout process.
</p>
          <h3 className='h3-term'>7. Returns and Refunds</h3>
          <p className='term-p'>Our platform adheres to a returns and refund policy that applies to all purchases. Customers may be eligible for returns or refunds under certain conditions, such as defective products or incorrect items.
Vendors are expected to comply with our returns policy and facilitate the refund process if necessary.
</p>
          <h3 className='h3-term'>8. Intellectual Property</h3>
          <p className='term-p'>All content, logos, and materials on this website are the intellectual property of Kasuwa Mall or its vendors. Any unauthorized use of such content is prohibited.</p>
          <h3 className='h3-term'>9. Liability</h3>
          <p className='term-p'>Kasuwa Mall is not responsible for any direct or indirect damages arising from the use of this platform, including but not limited to issues with product quality, delayed deliveries, or incorrect orders.
Vendors are fully responsible for the products they sell, and any disputes arising from product issues must be resolved between the vendor and the customer.
</p>
          <h3 className='h3-term'>10. Modification of Terms</h3>
          <p className='term-p'>We reserve the right to modify these terms and conditions at any time. All changes will be posted on the website, and continued use of the platform implies acceptance of these updated terms</p>
          <h3 className='h3-term'>11. Governing Law</h3>
          <p className='term-p'>These terms and conditions are governed by and construed in accordance with the laws of Nigeria/Africa. Any disputes arising from this agreement shall be subject to the exclusive jurisdiction of the courts of Nigeria/Africa.</p>
          <h3 className='h3-term'>12. Contact Information</h3>
          <p className='term-p'>For questions or concerns regarding these terms, please contact us at <Link to=""> help@kasuwamall.com</Link></p>
        </div>
      </Container>
    </div>
  )
}

export default Terms_Cond