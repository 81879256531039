import React, { useState } from "react";
import { globalColor } from "../utils/Helper";
import { CiHeart, CiMail, CiShop } from "react-icons/ci";
import {
  MdDelete,
  MdOutlineCheckroom,
  MdOutlineChildCare,
  MdOutlineDryCleaning,
  MdOutlineElectricalServices,
  MdOutlineFaceRetouchingNatural,
  MdOutlineHealthAndSafety,
  MdOutlineHome,
  MdOutlineKitchen,
  MdOutlineLocalBar,
  MdOutlinePending,
  MdOutlinePhoneAndroid,
  MdOutlineSell,
  MdOutlineShoppingCart,
  MdOutlineSportsEsports,
  MdOutlineSportsSoccer,
} from "react-icons/md";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { IoHome, IoHomeOutline } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import "./shopsidebar.css";
import { Badge, Button } from "reactstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/action/auth";
import logo from "../Images/KASUWAMALL__2_-removebg-preview.png";
import KasuwaLogo from "../Images/KASUWAMALL cut.png";

const ShopSidebar = ({ sidebarVisible, toggleSidebar, handlelogin_click }) => {
  const {
    shop: { carts },
    auth: { user, authenticated },
  } = useSelector((s) => s);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("@@bits_lis");
    dispatch(logout());
    navigate("/");
    toggleSidebar();
    setIsLoggedIn(false);
  };
  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };
  const category = [
    {
      id: 1,
      title: "Appliances",
      name: "appliances",
      icon: <MdOutlineKitchen className="icon" />,
    },
    {
      id: 2,
      title: "Phones & Tablets",
      name: "gadgets",
      icon: <MdOutlinePhoneAndroid className="icon" />,
    },
    {
      id: 3,
      title: "Beauty",
      name: "beauty",
      icon: <MdOutlineFaceRetouchingNatural className="icon" />,
    },
    {
      id: 4,
      title: "Electronics",
      name: "electronics",
      icon: <MdOutlineElectricalServices className="icon" />,
    },
    {
      id: 5,
      title: "Fashion",
      name: "fashion",
      icon: <MdOutlineCheckroom className="icon" />,
    },
    {
      id: 6,
      title: "Baby Products",
      name: "baby-Product",
      icon: <MdOutlineChildCare className="icon" />,
    },
    {
      id: 7,
      title: "Gaming",
      name: "gaming",
      icon: <MdOutlineSportsEsports className="icon" />,
    },
    {
      id: 8,
      title: "Drinks",
      name: "drinks",
      icon: <MdOutlineLocalBar className="icon" />,
    },
    {
      id: 9,
      title: "Home & Office",
      name: "home&office",
      icon: <MdOutlineHome className="icon" />,
    },
    {
      id: 10,
      title: "Health",
      name: "health",
      icon: <MdOutlineHealthAndSafety className="icon" />,
    },
    {
      id: 11,
      title: "Fabrics",
      name: "fabric",
      icon: <MdOutlineDryCleaning className="icon" />,
    },
    {
      id: 12,
      title: "Sporting Goods",
      name: "sporting",
      icon: <MdOutlineSportsSoccer className="icon" />,
    },
  ];
  return (
    <>
      {sidebarVisible && (
        <>
          <div
            className="sidebar-header py-2 d-flex justify-content-between px-3"
            style={{
              paddingRight: "20px",
              backgroundColor: globalColor.color1,
            }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              //   style={{ gap: "10px" }}
            >
              <AiOutlineClose
                style={{ fontSize: "28px", color: globalColor.color2 }}
                className="me-3"
                onClick={toggleSidebar}
              />
              <Link
                className="logo-text text-decoration-none"
                style={{
                  color: globalColor.color2,
                  fontWeight: "bold",
                  width: "80%",
                  height: "40px",
                }}
                to={"/"}
              >
                {/* {} */}
                <img
                  src={KasuwaLogo}
                  alt="kasuwamall logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    margin: 0,
                  }}
                />
              </Link>
            </div>
            <div
              className="position-relative"
              onClick={() => navigate("/cart")}
            >
              <MdOutlineShoppingCart
                style={{ fontSize: "30px", color: globalColor.color2 }}
                className="w-100"
              />
              <Badge
                color="secondary"
                className="rounded-circle cart-badge position-absolute"
                style={{ color: globalColor.color2 }}
              >
                {carts.length > 0
                  ? carts.reduce((p, c) => p + parseInt(c.qty), 0)
                  : 0}
              </Badge>
            </div>
          </div>
          {authenticated ? (
            <div className="sidebar" style={{ width: "100%" }}>
              <div
                className="p-3 text-white sidebar-head d-flex justify-content-between align-items-center"
                style={{ backgroundColor: globalColor.color3 }}
              >
                <div className="">
                  <h4 className="p-0 m-0">Welcome back, {user.firstname}</h4>
                  <p className="p-0 m-0">{user.email}</p>
                </div>
              </div>
              <div className="">
                <h5 className="sidebar-heading">Account Details</h5>
                <div className="p-2 sub-items">
                  <p
                    className="px-3"
                    onClick={() => {
                      navigate("/account/orders");
                      toggleSidebar();
                    }}
                  >
                    <CiShop className="icon" />
                    <span className="ml-2">Orders</span>
                  </p>
                  <hr />
                  <p
                    className="px-3"
                    onClick={() => {
                      navigate("/account/orders");
                      toggleSidebar();
                    }}
                  >
                    <MdOutlinePendingActions className="icon" />
                    <span className="ml-2">Pending Items</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <CiMail className="icon" />
                    <span className="ml-2">Messages</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <CiHeart className="icon" />
                    <span className="ml-2">Saved Items</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <Link
                      to={"https://app.kasuwamall.com/"}
                      className="text-dark"
                      style={{ textDecoration: "none" }}
                    >
                      <MdOutlineSell className="icon" />
                      <span className="ml-2">Sell on KASUWAMALL</span>
                    </Link>
                  </p>
                  <hr />
                </div>
              </div>
              <div className="">
                <h5 className="sidebar-heading">Settings</h5>
                <div className="p-2 sub-items">
                  <p
                    className="px-3"
                    onClick={() => {
                      navigate("/account");
                      toggleSidebar();
                    }}
                  >
                    <CiShop className="icon" />
                    <span className="ml-2">My Profile</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <IoHomeOutline className="icon" />
                    <span className="ml-2">My Address</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <AiOutlineDelete className="icon" />
                    <span className="ml-2">Delete Account</span>
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="not-auth p-1 pt-3 px-5">
                <Button
                  className="not-auth-btn"
                  style={{
                    background: "transparent",
                    border: "2px solid #a52a2a",
                    color: "#a52a2a",
                  }}
                  onClick={handlelogin_click}
                >
                  Log In / Sign Up
                </Button>
              </div>
              <hr />
              <div className="">
                <div className="p-2 pt-1 sub-items">
                  <p
                    className="px-3"
                    onClick={() => {
                      handleCategoryClick();
                      //   navigate("/account/orders");
                      toggleSidebar();
                    }}
                  >
                    <MdOutlinePendingActions className="icon" />
                    <span className="ml-2">Pending Items</span>
                  </p>
                  <hr />
                  <p
                    className="px-3"
                    onClick={() => {
                      handleCategoryClick();
                    }}
                  >
                    <CiHeart className="icon" />
                    <span className="ml-2">Saved Items</span>
                  </p>
                  <hr />
                  <p className="px-3">
                    <Link
                      to={"https://app.kasuwamall.com/"}
                      className="text-dark"
                      style={{ textDecoration: "none" }}
                    >
                      <MdOutlineSell className="icon" />
                      <span className="ml-2">Sell on KASUWAMALL</span>
                    </Link>
                  </p>
                  <hr />
                </div>
              </div>
            </>
          )}
          <div className="">
            <h5 className="sidebar-heading">Top Selling Categories</h5>
            <div className="p-2 sub-items">
              {category.map((category) => (
                <>
                  <p
                    className="px-3"
                    onClick={() => {
                      handleCategoryClick(category.name);
                      toggleSidebar();
                    }}
                  >
                    {/* <MdOutlinePhoneAndroid className="icon" /> */}
                    {category.icon}
                    <span className="ml-2">{category.title}</span>
                  </p>
                  <hr />
                </>
              ))}
            </div>
            {authenticated ? (
              <div className="p-4">
                <Button
                  className="logout-btn text-center"
                  style={{
                    background: globalColor.color2,
                    color: globalColor.color3,
                  }}
                  onClick={handleLogout}
                >
                  Log Out
                </Button>
                {/* <h5></h5> */}
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ShopSidebar;
