import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "reactstrap";
import { _get } from "../../utils/Helper";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import "./Dashboard.css";
// import { items } from "../Items";

export default function AllOrders({}) {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const location = useLocation();
  const order = location.state?.order;

  const userDetails = useSelector((state) => state.auth.user);

  // const {
  //   // shop: { carts },
  //   auth: { user },
  // } = useSelector((s) => s);

  const getAllOrders = () => {
    setFetching(true);
    _get(
      `api/gerordersbycustomerid?customer_id=${userDetails.id}`,
      (resp) => {
        setOrders(resp.results);
        console.log(orders);
        setFetching(false);
      },
      (err) => {
        setError(err);
        setFetching(false);
      }
    );
  };

  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <div className="">
      <Row className="">
        <Col md={12}>
          <Card className="shadow-sm profile-card">
            <Row className="p-2">
              {/* <Col md={10}>
                <h6>All Ordered Items</h6>
              </Col> */}
              {/* {JSON.stringify(userDetails)} */}
              <Col md={2}>
                {/* <Edit className="profile-icon" size="1.3em" title="edit" /> */}
              </Col>
            </Row>
            <hr style={{ margin: 0, padding: 0 }}></hr>

            <Row className="">
              {orders.map((item, index) => (
                <Col md={12} key={index}>
                  <Card className="shadow-sm orders-card m-2 p-3">
                    <Row>
                      <Col md={10}>
                        <Row>
                          <Col md={3}>
                            <img
                              src={
                                // "https://yge.wvi.mybluehost.me/test/sanda-server/uploads/" +
                                item.order_image
                              }
                              alt=""
                              style={{
                                // margin: "auto",
                                width: "100%",
                                height: "25vh",
                                // width: 200,
                                // height: 200,
                                borderRadius: "20px",
                                border: ".5px solid #bbb",
                              }}
                            />
                          </Col>
                          <Col md={9} className="order-det-col">
                            <hr style={{ margin: 0, padding: 2 }} />
                            <p>
                              <span style={{ fontWeight: "bold" }}>Item: </span>
                              {item.product}
                            </p>
                            <hr style={{ margin: 0, padding: 2 }} />
                            <p>
                              <span style={{ fontWeight: "bold" }}>Date: </span>
                              {item.createdAt?.slice(0, 10)}
                            </p>
                            <hr style={{ margin: 0, padding: 2 }} />
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Status:{" "}
                              </span>
                              {item.status}
                            </p>
                            <hr style={{ margin: 0, padding: 2 }} />
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                Number:{" "}
                              </span>
                              {item.order_no}
                            </p>
                            <hr style={{ margin: 0, padding: 2 }} />
                          </Col>
                        </Row>
                        {/* <Table responsive>
                          <tbody className="">
                            <td className="">
                              <img
                                src={
                                  // "https://yge.wvi.mybluehost.me/test/sanda-server/uploads/" +
                                  item.order_image
                                }
                                alt=""
                                style={{
                                  // margin: "auto",
                                  // width: "50%",
                                  width: 200,
                                  height: 200,
                                  borderRadius: "20px",
                                }}
                              />
                            </td>
                            <td className="d-flex flex-column ">
                              <td>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Item:{" "}
                                  </span>
                                  {item.product}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Date:{" "}
                                  </span>
                                  {item.createdAt?.slice(0, 10)}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Status:{" "}
                                  </span>
                                  {item.status}
                                </p>
                              </td>
                              <td>
                                <p>
                                  <span style={{ fontWeight: "bold" }}>
                                    Number:{" "}
                                  </span>
                                  {item.order_no}
                                </p>
                              </td>
                            </td>
                          </tbody>
                        </Table> */}
                      </Col>
                      <Col
                        md={2}
                        className="d-flex justify-content-center align-items-start"
                      >
                        <Button className="btn btn-sm btn-warning">
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
