import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Card } from "reactstrap";
import { _fetchApi } from "../../redux/action/api";
// import "react-tabs/style/react-tabs.css";
import PendingOrders from "./PendingOrders";
import RecievedOrders from "./RecievedOrders";
import CancelledOrder from "./CancelledOrder";
import AllOrders from "./AllOrders.js";
import "./Dashboard.css";
export default function Orders() {
  const [select, setSelect] = useState(2);
  const [orders, setOrders] = useState([]);
  const { customer } = useSelector((s) => s.auth);

  const listOrders = (customer_id) => {
    if (customer_id && orders.length < 1) {
      _fetchApi(
        `/gerordersbycustomerid=${customer_id}`,
        (resp) => {
          setOrders(resp.data);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  useEffect(() => {
    listOrders(customer?.accountNo);
  });

  return (
    <div className="">
      {/* {JSON.stringify({ orders })} */}
      <Card className="sidebar-card px-4 py-4 shadow-sm orders-div">
        <div>
          <h4 className="headings" style={{ margin: 0, padding: 0 }}>
            My Order Details
          </h4>
        </div>
        <hr style={{}}></hr>
        <div className="tabs-div">
          <Button
            onClick={() => setSelect(2)}
            className={select === 2 ? "selected" : "not-selected"}
          >
            All Orders
          </Button>
          <Button
            onClick={() => setSelect(3)}
            className={select === 3 ? "selected" : "not-selected"}
          >
            Pending Order
          </Button>
          <Button
            onClick={() => setSelect(4)}
            className={select === 4 ? "selected" : "not-selected"}
          >
            Received Order
          </Button>
          <Button
            onClick={() => setSelect(5)}
            className={select === 5 ? "selected" : "not-selected"}
          >
            Cancelled Order
          </Button>
        </div>
        {select === 2 ? (
          <AllOrders
            orders={orders.filter((or) => or.req_status === "pending")}
          />
        ) : select === 3 ? (
          <PendingOrders
            orders={orders.filter((or) => or.req_status === "approved")}
          />
        ) : select === 4 ? (
          <RecievedOrders
            orders={orders.filter((or) => or.req_status === "approved")}
          />
        ) : (
          <CancelledOrder
            orders={orders.filter((or) => or.req_status === "cancel")}
          />
        )}
      </Card>
    </div>
  );
}
